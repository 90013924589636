
const sampleCategories = [
    {
        CategoryId: 1,
        CategoryName: 'Test Cat 1',
        CategoryDescription: 'Test Description 1'
    },
    {
        CategoryId: 2,
        CategoryName: 'Test Cat 2',
        CategoryDescription: 'Test Description 2'
    }
]

export default sampleCategories;