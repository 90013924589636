//Step 1 - Read - Create Categories component, pay attention to the imports
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import sampleCategories from '../../Utilities/sampleCategories'
import './Categories.css'
import SingleCategory from './SingleCategory'
import axios from 'axios'
//STEP - CREATE
import {useAuth} from '../../contexts/AuthContext'
import CatCreate from './CatCreate';


export default function Categories() {
  //Step 2 - Read - Create the hook
  const [categories, setCategories] = useState(sampleCategories);
  //Step - Create
  const [showCreate, setShowCreate] = useState(false);
  const {currentUser} = useAuth();


  //Step 4 - Inject data into the component
  const getCategories = () => {
    axios.get('http://localhost:54608/api/categories/').then(response => {
      setCategories(response.data)
    })
  }

  useEffect(() => {
    getCategories();
  }, []);

  //Step 3 - Read - Create the UI
  return (
    <section className="categories">
      <article className="bg-info p-5">
        <h1 className="text-center">Categories Dashboard</h1>
      </article>
      {currentUser.email === 'james.caldwell82@outlook.com' &&
        <div className="bg-dark p-2 mb-3 text-center">
          {showCreate ?
            <>
            <button onClick={() => setShowCreate(false)} className="btn btn-warning">Cancel</button>
            <CatCreate getCategories={getCategories} setShowCreate={setShowCreate} />
            </> :
            <button onClick={() => setShowCreate(true)} className="btn btn-info">Create New Category</button>
          }
        </div>
      }
      <Container>
        <table className="table bg-info table-dark mt-3 mb-3">
          <thead className="table-secondary text-uppercase">           
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  {currentUser.email === 'james.caldwell82@outlook.com' &&
                    <th>Actions</th>
                  }
                </tr>             
          </thead>
          <tbody>
            {categories.map(x =>
              <SingleCategory 
                key={x.CategoryId} 
                category={x}
                getCategories={getCategories} />
            )}
          </tbody>
        </table>
      </Container>
    </section>
  );
}
