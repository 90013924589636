import './App.css';
import Bootstrap from './components/Bootstrap/Bootstrap';
import Navigation from './components/Navigation';
import Resources from './components/Resources/Resources';
import Categories from './components/Categories/Categories';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
//For Router functionality in our app:
//1. In the terminal type 'npm install bootstrap@5.1.3
//2. In the terminal type 'npm install react-bootstrap'
//3. In the terminal type 'npm install react-router-dom'
//4. Create Navigation.js
//5. In App.js import react-router-dom components necessary (BrowserRouter, Routes, Route)
//6. In App.js, create the Router in the return (/todos, /categories, /* for NotFound)
//7. Create NotFound.js and render the NotFound in App.js as seen below
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Auth/Login';
import Routing from './components/Routing/Routing'
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <div>
        <section>
          <article></article>
        </section>
      </div>
      <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
          {/* For every route we want to, we can point the router to load a specific component. */}
          <Route path="/" element={<ProtectedRoute><Resources /></ProtectedRoute>} />
          <Route path="login" element={<Login />} />
          <Route path="bootstrap" element={<Bootstrap />}/>
          <Route path="resources" element={<ProtectedRoute><Resources /></ProtectedRoute>} />
          <Route path="categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
          <Route path="routing" element={<Routing />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
