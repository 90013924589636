//Step 1 - Read
//create the sampleResources object below that will display test resources for the initial rendering
const sampleResources = [
    {
        ResourceId: 1, 
        Name: 'Test Resource 1',
        Url: 'https://reactjs.org',
        LinkText: 'some docs',
        Description: 'This is the official React documentation.'
    },
    {
        ResourceId: 2, 
        Name: 'Test Resource 2',
        Url: 'https://reactjs.org/docs/hooks-intro.html',
        LinkText: 'some other docs',
        Description: null
    },
    {
        ResourceId: 3, 
        Name: 'Test Resource 3',
        Url: 'https://react-bootstrap.github.io/',
        LinkText: 'some docs',
        Description: 'This is documentation on utilizing Bootstrap React component.'
    },
    {
        ResourceId: 4, 
        Name: 'Test Resource 4',
        Url: 'https://reactrouter.com/web/guides/quick-start',
        LinkText: 'some docs',
        Description: 'This is documentation on the react-router-dom package.'
    }
]

//Make sure to export the sampleResources object
export default sampleResources;