import React, {useState, useEffect} from 'react';
import './Resources.css'
//We import the local data into this component so we can work with the UI before we get our remote data.
import sampleResources from '../../Utilities/sampleResources'
import { Container } from 'react-bootstrap';
import SingleResource from './SingleResource';
//STEP - CREATE
import ResourceCreate from './ResourceCreate'
import {useAuth} from '../../contexts/AuthContext'

//npm install axios - tool that will manage HTTP requests to our api
import axios from 'axios'

export default function Resources() {
  //Below we create a React hook to capture our resources into an state variable. To do this we need to import useState
  const [resources, setResources] = useState(sampleResources);
  //const [resources, setResources] = useState([]); - This is what the Hook would need to look like if we just wanted it to remain empty until we get the data from the API.

  //STEP - CREATE
  const [showCreate, setShowCreate] = useState(false);
  //Create functionality needs to be protected from any user and only show when the user is a specific user
  const {currentUser} = useAuth();

  //This is our function to retrieve data from the ResourceAPI.
  const getResources = () => {
    axios.get('http://localhost:54608/api/resources/').then(response => {
      setResources(response.data);
    })
  }

  //useEffect - which will call the getResources function as the component mounts in the UI (Virtual DOM)
  useEffect(() => {
    getResources();
  }, []);

  return (
      <section className="resources">
          <article className="bg-info p-5">
            <h1 className="text-center">Resources Dashboard</h1>
          </article>
          {/* STEP - CREATE */}
          {currentUser.email === 'james.caldwell82@outlook.com' &&
            <div className="bg-dark p-2 mb-3 text-center">
              <button className="btn btn-info" onClick={() => setShowCreate(!showCreate)}>
                {!showCreate ? 'Create New Resource' : 'Close Form'}  
              </button> 
              <div className="createContainer">
                {showCreate &&
                  <ResourceCreate 
                      getResources={getResources}
                      setShowCreate={setShowCreate}
                  />
                }
              </div>
            </div>
          }
          <Container>
            <article className="resourceGallery row justify-content-center">
              {/* EDIT/DELETE - STEP 1 Add props for getResources and categories -- move to SingleResource */}
              {resources.map(x =>               
                <SingleResource 
                  key={x.ResourceId} 
                  resource={x} 
                  getResources={getResources}/>
              )}
            </article>
          </Container>
      </section>
  );
}
