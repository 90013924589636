import React from 'react';
import { Nav, Navbar } from 'react-bootstrap'
//npm install react-router-dom
//react-router-dom is a code package we install to bring routing functionality to our application. When a user types /resources behind our domain, react-router-dom makes it possible to load a specific component when that route is typed
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext';

export default function Navigation() {
    const { currentUser, authenticate, logout } = useAuth();

    return (
        <Navbar variant="dark" bg="dark" expand="md" className="p-3">
            <Navbar.Brand href="/">ResourcePlus</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="mr-auto">
                    {currentUser &&
                        <>
                            <Link to="/resources" className="nav-link">Resources</Link>
                            <Link to="/categories" className="nav-link">Categories</Link>
                        </>
                    }
                    <Link to="/bootstrap" className="nav-link">Bootstrap</Link>
                    <Link to="/routing" className="nav-link">Routing/Auth</Link>
                    {currentUser ?
                        <Nav.Link onClick={() => logout()}>Logout</Nav.Link> :
                        <Nav.Link onClick={() => authenticate()}>Login</Nav.Link>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
