//npm install firebase@8.10.0
//Firebase is a cloud computing tool that includes a lot of features like db management, messaging, machine learning, and authentication. 
//Steps for authentication
//1. Create a Firebase application online and get the connection info from their docs
//2. Configure/Initialize firebase by creating the .env and base.js files
    //a. Make sure the .env file is at the root of the entire project
    //b. base.js is at the root of the src folder
//3. Using Firebase and GitHub websites, configure the FirebaseApp and GitHub to communicate with each other.
//4. Create a context to hold user information, login/logout functions

import firebase from 'firebase/app'
//auth functionality from firebase
import 'firebase/auth'

//The object below is our way of connecting this app to Firebase so we can utilize the authentication functionality.
const firebaseApp = firebase.initializeApp({
    // apiKey: "AIzaSyDZxf8xXhY85Mjl3MZJlv1Vsjn6HBHdPBk",
    // authDomain: "reactresource-55e08.firebaseapp.com",
    // projectId: "reactresource-55e08",
    // storageBucket: "reactresource-55e08.appspot.com",
    // messagingSenderId: "293312972870",
    // appId: "1:293312972870:web:296aa5f5cd67ecbdf62247"
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
})

//Below initializes the authentication in a Firebase application
export const authResult = firebase.auth();

export default firebaseApp;