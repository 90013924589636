import React from 'react'
import {Formik, Form, Field} from 'formik'
import catSchema from '../../Utilities/validationSchema'
import axios from 'axios'

export default function CatForm(props) {

    const handleSubmit = (values) => {
        console.log(values)
        //Before we write the rest of this function, lets test to make sure it logs the value in the console window
        if(!props.category){
            //create code
            const categoryToCreate = {
                CategoryName: values.CategoryName,
                CategoryDescription: values.CategoryDescription
            }

            console.log(categoryToCreate);

            axios.post('http://localhost:54608/api/categories/', categoryToCreate).then(() => {
                props.setShowCreate(false)
                props.getCategories();
            })
        }
        else{
            //edit code
            const category = {
                CategoryId: props.category.CategoryId,
                CategoryName: values.CategoryName,
                CategoryDescription: values.CategoryDescription
            }

            axios.put('http://localhost:54608/api/categories/', category).then(() => {
                props.getCategories();
                props.setShowEdit(false);
            })
        }
    }

  return (
    <div className="createCategory m-2 text-white text-center">
        <Formik
            initialValues={{
                CategoryName: props.category ? props.category.CategoryName : '',
                CategoryDescription: props.category ? props.category.CategoryDescription : ''
            }}
            validationSchema={catSchema}
            onSubmit={values => handleSubmit(values)}>

            {({errors, touched}) => (
                <div className="container">
                    <Form id="catForm" className="row text-center m-auto">
                        <div className="form-group m-1 p-1">
                            <Field name="CategoryName" className="form-control" placeholder="Name" />
                            {errors.CategoryName && touched.CategoryName ? (
                                <div className="text-danger">{errors.CategoryName}</div>
                            ) : null}
                        </div>
                        <div className="form-group m-1 p-1">
                            <Field name="CategoryDescription" className="form-control" placeholder="Description" />
                            {errors.CategoryDescription && touched.CategoryDescription ? (
                                <div className="text-danger">{errors.CategoryDescription}</div>
                            ) : null}
                        </div>
                        <div className="form-group m-1">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </Form>
                </div>                
            )}
        </Formik>
    </div>
  )
}
