import React, { useState, useEffect } from 'react'
//Formik is the tool that will build the form and keep track of what the user types into that form
import { Formik, Form, Field } from 'formik'
import { resourceSchema } from '../../Utilities/validationSchema'
import axios from 'axios'

export default function ResourceForm(props) {
    //Create a React Hook that will capture the categories from the API so we can use them to populate the select element for our form.
    const [categories, setCategories] = useState([]);

    //copied from Categories.js - this will populate our categories Hook
    const getCategories = () => {
        axios.get('http://localhost:54608/api/categories/').then(response => {
            setCategories(response.data)
        })
    }


    const handleSubmit = (values) => {
        console.table(values)
        //If the prop called resource has been passed in, then we assume the form should utilize edit functionality. Otherwise, we will be in create mode
        if (!props.resource) {
            //Assemble a temporary resource object for the HTTP request
            const resourceToCreate = {
                Name: values.Name,
                Description: values.Description,
                LinkText: values.LinkText,
                Url: values.Url,
                CategoryId: values.CategoryId 
            }
            //Post to the API, then to call props.getResources (located in the Resources.js) and props.setShowCreate(false) to close the form
            axios.post('http://localhost:54608/api/resources/', resourceToCreate).then(() => {
                props.getResources();//This will request a GET http request to our API...get all the resources again
                props.setShowCreate(false);
            })
        }
        else {
            console.log('Edit Mode');
            const resourceToEdit = {
                ResourceId: props.resource.ResourceId,
                Name: values.Name,
                Description: values.Description,
                Url: values.Url,
                LinkText: values.LinkText,
                CategoryId: values.CategoryId
            }

            axios.put('http://localhost:54608/api/resources/', resourceToEdit).then(() => {
                //get resources
                props.getResources();
                //close the edit form
                props.setShowEdit(false);
            })
        }
    }

    //useEffect is written so the component will gather the categories as this form is loaded to the UI
    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Formik
            initialValues={{
                //Here we assign the values of the objects in the form's initial values. For create, we will set all of our initial values to an emtpy string. As we add edit functionality, these will become ternary operators that will, in edit mode, make the initial value the values from that specific resource.
                Name: props.resource ? props.resource.Name : '',
                Url: props.resource ? props.resource.Url : '',
                LinkText: props.resource ? props.resource.LinkText : '',
                Description: props.resource ? props.resource.Description : '',
                CategoryId: props.resource ? props.resource.CategoryId : ''
            }}
            validationSchema={resourceSchema}
            onSubmit={(values) => handleSubmit(values)}
        >

            {({ errors, touched }) => (
                <Form id="resourceForm">
                    <div className="form-group m-3">
                        <Field name="Name" className="form-control" placeholder="Name" />
                        {errors.Name && touched.Name ? (
                            <div className="text-danger">{errors.Name}</div>
                        ) : null}
                    </div>
                    <div className="form-group m-3">
                        <Field name="Url" className="form-control" placeholder="Url" />
                        {errors.Url && touched.Url ? (
                            <div className="text-danger">{errors.Url}</div>
                        ) : null}
                    </div>
                    <div className="form-group m-3">
                        <Field name="LinkText" className="form-control" placeholder="LinkText" />
                        {errors.LinkText && touched.LinkText ? (
                            <div className="text-danger">{errors.LinkText}</div>
                        ) : null}
                    </div>
                    <div className="form-group m-3">
                        <Field name="Description" as="textarea" className="form-control" placeholder="Description" style={{ resize: 'none', height: '5em' }} />
                        {errors.Description && touched.Description ? (
                            <div className="text-danger">{errors.Description}</div>
                        ) : null}
                    </div>
                    <div className="form-group m-3">
                        <Field as="select" name="CategoryId" className="form-control">
                            <option value="0" disabled>[--Please Choose--]</option>
                            {/* Below we map the categories hook to an option element for each category. Because we map these, we need to add a key attribute that is unique (ID) and a value which will capture what the user selects from the list. */}
                            {categories.map(cat =>
                                <option key={cat.CategoryId} value={cat.CategoryId}>{cat.CategoryName}</option>
                            )}
                        </Field>
                    </div>
                    <div className="form-group m-3">
                        <button type="submit" className="btn btn-info m-3">Submit Resource to API</button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
