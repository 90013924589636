import React, {useState} from 'react'
//Edit/Delete Steps 
//1.Resources.js add prop for getResources - this will update the component
//2. SingleResource.js add buttons for edit and delete - optionally add FontAwesomeIcons
//3. Wire up the delete function in SingleResource
//4. Create the ResourceEdit.js
//5. Conditionally render ResourceEdit below - we create a React Hook for the editForm called showEdit
//6. Configure the ResourceForm to incorporate Edit functionality
//7. Lock down the Edit and Delete buttons in SingleResource - currentUser functionality

//imports for FontAwesome icons
/*
npm i --save @fortawesome/fontawesome-svg-core
npm install --save @fortawesome/free-solid-svg-icons
npm install --save @fortawesome/react-fontawesome
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import ResourceEdit from './ResourceEdit'
import {useAuth} from '../../contexts/AuthContext'

//initialize the Font Awesome library in this file
library.add(fas);


export default function SingleResource(props) {
  //React hook - will show/hide the edit form
  const [showEdit, setShowEdit] = useState(false);
  const {currentUser} = useAuth();

  const deleteResource = (id) => {

      //Check with the user to ensure they want to delete the resource...upon the user clicking ok, make a request to the API to delete that resource, and then we will get our resources from the API to reflect the change.
      if(window.confirm(`Are you sure you want to delete ${props.resource.Name}?`)){
        //we need to import axios into this component so we can we make a request to the API
        axios.delete(`http://localhost:54608/api/resources/${id}`).then(() => {props.getResources()})
      }
  }

  return (
    <div className="singleResource col-md-5 m-4">
          {currentUser.email === 'james.caldwell82@outlook.com' && 
        <div>
          <button id="editLink" onClick={() => setShowEdit(true)}>
            <FontAwesomeIcon icon={['fas', 'edit']} />
          </button>
          <button id="deleteLink" onClick={() => deleteResource(props.resource.ResourceId)}>
            <FontAwesomeIcon icon={['fas', 'trash-alt']} />
          </button>
        {showEdit &&
          <ResourceEdit
          resource={props.resource}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          getResources={props.getResources} />
        }
        </div>
      }

        <h3>{props.resource.Name}</h3>

        {props.resource.Description !== null ?
            <p>{props.resource.Description}</p> :
            <p>No Description Provided</p>
        }

        <a href={props.resource.Url} target="_blank" rel="noreferrer" className="btn btn-info">Visit {props.resource.LinkText}</a>
    </div>
  )
}
